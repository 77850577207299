import axios from "@/plugins/axios";
import { isNotUpload, PageResult, SearchService } from ".";

const EquipmentPaths = {
  List: "api/Equipment",
  Get: (id: string, isForce?: boolean) =>
    `api/Equipment/${id}?isAll=true${isForce === true ? "&isForce=true" : ""}`,
  Delete: (id: string) => `api/Equipment/${id}`,
  Save: (id: string) => `api/Equipment/${id}`,
  CloudUpload: (id: string) => `api/Equipment/${id}/synchronize`,
  Post: "api/Equipment",
};
export type SearchParams = {
  name: string;
  isUploaded: string;
};

export type EquipmentModel = {
  id: string;
  name: string | null;
  updateAt: string;
  isDeleted: boolean;
  uploadAt: string;
};
export type SearchResult = {
  list: EquipmentModel[];
  page: PageResult;
};

export class EquipmentService extends SearchService<
  SearchParams,
  EquipmentModel
> {
  constructor() {
    super({ omit: ["detail"] });
  }
  protected get getPath(): string {
    return EquipmentPaths.List;
  }
  async cloudUpload(id: string): Promise<boolean> {
    try {
      const url = EquipmentPaths.CloudUpload(id);
      await axios.post(url);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }
  async get(id: string, isForce?: boolean): Promise<EquipmentModel> {
    try {
      const url = EquipmentPaths.Get(id, isForce);
      const response = await axios.get<EquipmentModel>(url);
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async save(model: EquipmentModel): Promise<void> {
    try {
      const url = EquipmentPaths.Save(model.id);
      await axios.put(url, model);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async delete(id: string): Promise<void> {
    try {
      const url = EquipmentPaths.Delete(id);
      await axios.delete(url);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async post(name: string): Promise<EquipmentModel> {
    try {
      const form = new FormData();
      form.append("name", name);
      const response = await axios.post<EquipmentModel>(
        `./${EquipmentPaths.Post}`,
        form
      );
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
export const Equipment = new EquipmentService();

/**
 * アップロード可否
 * @param model
 * @returns
 */
export function isUploadable(model: EquipmentModel): boolean {
  const uploadAt = model.uploadAt;
  // 未アップロードならアップロード可
  if (uploadAt == null) return true;
  // アップロード日 が 更新日 よりも前
  return isNotUpload(model);
}
